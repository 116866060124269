@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.inner .form-control {
  display: block;
  width: 100%;
  height: 32px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 400;
}

.inner label {
  position: absolute;
  background: #fff;
  font-size: 9px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0 6px;
  margin: 0 3px;
  top: -6px;
}

.inner-card {
  padding: 0px 0px;
}

.inner .switch {
  transform: rotate(90deg);
}

.inner .form-group {
  position: relative;
}

.inner textarea.form-control {
  height: auto;
}

.inner-sec button {
    margin-right: 15px;
    border-radius: 20px;
}

.settings{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    float: left;
    height: auto;
    width: 100%;
    background-color: #fff;
}

.menu{
    width: 200px;
}

.menu-ig{
    width: 200px;
}

.macro-list{
    margin: 0 10px 50px 20px;
    max-width: 700px;

}

.macro-form{
    text-align: left;
}

.help-popover {
    max-width: 600px;
}

.ant-form-item {
    margin-bottom: 12px;
}

body {
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier Unreviewed',*/
/*    monospace;*/
/*}*/

.btn-style {
    padding: 5px;
    border-radius: 6px;
}

.ant-btn-primary {
    background: #585980;
    border-color: #585980;
}

.ant-btn-primary:hover {
    background: #7075d9;
    border-color: #7075d9;
}

::selection {
    background: #585980;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizonal > .ant-menu-item:focus,
.ant-menu-horizontal > .ant-menu-item-selected {
    color: #585980;
}

.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom: 2px solid #585980!important
}

.ant-btn:hover, .ant-btn:focus {
    color: #7075d9;
    border-color: #7075d9;
}


.invalid-feedback {
    display: block
}

#components-layout-demo-responsive .logo {
    margin: 16px;
    height: 40px;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}

.table-img-size {
    height: 180px;
}

.ant-card-body {
    padding: 8px !important;
}

.ant-card-head {
    padding: 0 10px;
}

element.style {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.ant-divider-horizontal {
    margin: 4px 0 !important;
}

.table-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f1f1f1;
    border-radius: 4px;
    margin-right: 3px;
    margin-left: 15px;
    display: inline-block;
}

.table-img-nr {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f1f1f1;
    margin-right: 10px;
    display: inline-block;
}

.unreviewed-border {
    border: 2px solid red;
}

.table-img2 {
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
}

.table-img3 {
    width: 100%;
    height: 80px;
    cursor: pointer;
}

.mod-img-1 {
    height: 200px;
}

.table-icon {
    max-width: 20px;
}

.table-cell-image {
    text-align: center;
    align-items: center;
    color: #000;
    font-size: 23px;
}

.login-div {
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center
}

.photo-details-div {
    position: absolute;
    bottom: 2%;
    width: 100%;
    padding-left: 15px;
    padding-bottom: 10px;
    color: white;
    text-shadow: 1px 1px black;
}

.photo-member-deets {
    width: 100%;
}

.photo-member-name {
    font-size: 16px;
    font-weight: 500;
}

.photo-mod-div {
    position: relative;
}

.mod-select {
    width: 100%;
}

.inner .lead {
    font-weight: 300;
    line-height: 5px;
}

.gx-chat-item {
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 6px;
    margin-bottom: 10px;
}

.gx-bubble {
    padding: 8px 12px;
    border-radius: 8px 8px 8px 0;
    position: relative;
    display: inline-block;
    white-space: pre-line;
    max-width: 300px;
    background-color: #eef1f6;
}

.gx-chat-item.gx-flex-row-reverse .gx-bubble {
    margin-left: 0;
    color: #fff;
    background-color: #585980;
    border-radius: 8px 8px 0 8px;
    text-align: left;
}

.gx-text-right {
    text-align: right !important;
}

.gx-mt-2, .gx-my-2 {
    margin-top: 0.5rem!important;
}

.gx-text-muted {
    color: #8c8c8c !important;
}

.gx-message{
    font-size: 14px;
    font-weight: 500;
}

.gx-time {
    font-size: 9px;
}

.gx-name {
    font-size: 10px;
    font-weight: 600;
    margin-top: 0px;
}

.gx-flex-row-reverse {
    flex-direction: row-reverse !important;
}

.holder_style {
    /*background: #fff;*/
    /*padding: 10px;*/
    /*  !*overflow-y: scroll;*!*/
    /*border: 1px solid #f0f0f0;*/
}

.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 0px 0;
}

.c-h {
    position: relative;
    width: 100%;
    height: 100%
}

.c-h-c {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 65%;
    padding-right: 20px;
    padding-left: 20px;
    overflow-y: scroll;
}

.over-c {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    right: 0;
    padding: 14px 8px 10px 8px;
    background: #fff;
}

/* Chat Only*/
.chat-only-message-container .gx-chat-item:first-child {
    margin-top:80px;
}

.chat-only-message-container {
    width: 100%;
    height: 50vh;
    position: relative;
    display: block;
}

.chat-only-messages {
    height: 100%;
    overflow-y: scroll;
}

.chat-only-input {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    width: 100%;
}

.chat-avatar {
    border: 2px solid #fff;
    border-radius: 8px;
}

.chat-header {
    z-index: 99;
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 8px;
    padding: 8px 20px 8px 8px;
    background-color: rgba(255, 255, 255, 0.85);
}

.chat-header-text {
    margin-left: 6px;
    margin-bottom: 0px!important;
}

.chat-row {
    padding: 10px;
}

.chat-open-button {
    margin-right: 10px;
}

.chat-open-button span, .chat-send-button span {
    display: inline-flex;
    align-items: baseline;
}

.dd .ant-select {
    width: 100%;
}

.ant-table-thead {
    background-color: white;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0px 0px 0px 0px;
}

.ant-table-small .ant-table-thead > tr > th {
    background-color: #ffffff;
}

/*.extra-small-button {*/
/*    padding:0px 5px 0px 5px;*/
/*}*/
/*.extra-small-button-selected {*/
/*    padding:0px 5px 0px 5px;*/
/*    background-color: #1890ff;*/
/*}*/
/*.extra-small-button-new {*/
/*    padding:0px 5px 0px 5px;*/
/*    background-color: #ff5050;*/

/*}*/

.site-drawer-render-in-current-wrapper {
    position: relative;
    height: 200px;
    padding: 48px;
    overflow: hidden;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
}

.site-drawer-render-in-current-wrapper-2 {
    position: relative;
    height: 200px;
    padding: 48px;
    overflow: scroll;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.hs {
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(6, calc(50% - 40px));
    grid-template-rows: minmax(150px, 1fr);
}


:root {
    --gutter: 10px;
}

.app {
    padding: var(--gutter) 0;
    display: grid;
    grid-gap: var(--gutter) 0;
    grid-template-columns: var(--gutter) 1fr var(--gutter);
    align-content: start;
}

.hs {
    display: grid;
    grid-gap: calc(var(--gutter) / 2);
    grid-template-columns: 10px;
    grid-template-rows: minmax(150px, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: calc(50% - var(--gutter) * 2);

    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(.75 * var(--gutter));
    margin-bottom: calc(-.25 * var(--gutter));
}

.hs:before,
.hs:after {
    content: '';
    width: 10px;
}
/* Login */
.login-container {
    text-align: center;
    padding-top: 10vh;
    max-width: 400px;
    background: #585980;
}

.login-holder {
    background: #fff;
    height: 40vh;
    min-height: 350px;
    width: 100%;
    padding: 15px;
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.45) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.5) 0px 30px 60px -30px;
}

.login-button-holder {
    margin-top: 150px;
}

.login-logo {
    position: absolute;
}

/* Demo styles */

ul {
    list-style: none;
    padding: 0;
}


.hs > li,
.item {
    scroll-snap-align: center;
    padding: calc(var(--gutter) / 2 * 1.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border-radius: 8px;
}

element.style {
    padding: 0px !important;
    min-height: 360px;
}

.ant-checkbox-group-item {
    font-size: 9pt;
}

.checkboxes {
    display: flex !important;
    align-items: center !important;
}

.tags {
    margin-top: -5px;
    margin-left: -10px;
    width: 25px;
}

.member-row, .members-row {
    align-items: center;
}

.preference-flags {
    display: inline-block !important;
    align-items: center !important;
    max-width: 15em;
}

.preference-flag {
    margin-bottom: 5px !important;
}

.hashtag-flag, .sub-flag, .photo-flags, .about-flag, .members-flag, .no-demo-flag, .country-flag, .facebook-flag, .appsflyer-flag, .platform-flag, .scam-alert-flag{
    display: table-row;
    align-items: center !important;
    text-align: center;
    vertical-align: middle;
    justify-content: center !important;
}

.members-flag img, .facebook-flag img, .appsflyer-flag img, .platform-flag img {
    height: unset;
    max-width: 90%;
    width: 1.35em;
}

.members-flag, .facebook-flag, .appsflyer-flag, .platform-flag {

}

/* Profile Row Photos*/
.photo-row {
    min-width: 900px;
}

.photo-row-div {
    float: left;
}

.photo-mod-tags-div {
    position: absolute;
}

.photo-mod-tags-row {
    margin-top: 0.3em;
    margin-left: 0.8em;
}

.photo-mod-tags-full {
    top: 1em;
    left: 1em;
}

.photo-mod-tags-div span {
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;
}

.photo-mod-tags-row span {
    font-size: 1.4em;
    line-height: 1.4em;
}

.photo-mod-tags-full span {
    font-size: 1.3em;
    line-height: 1em;
}

.photo-mod-tags-row span:first-of-type {
    padding-left: 7px;
}

.photo-mod-select-div {
    bottom: 0;
    width: 100%;
    padding: 8px 0 8px 0
    /*transform: translate(-50%, -50%);*/
    /*margin: 0 auto;*/
}

.photo-mod-quick-button-div div {
    width: 33%;
    padding: 0 4px 0 4px;
}

.photo-mod-quick-button-div button span {
    width: 2em;
}

.expanded-profile-image {
    width: 100%;
    max-width: 350px;
}

/* Profile Connect */
.profile-connect-tabs .ant-tabs-tab .anticon {
    font-size: 1.5em;
    margin: 0;
}

.profile-connect-tabs .profile-connect-tab-count {
    padding-left: 4px;
}

.flex-row-photos {
    white-space: nowrap;
}

.img-button {
    cursor: pointer;
}

.interaction-row {
    white-space: nowrap;
    display: block;
    margin: 0 auto;
}

.interaction-div {
    white-space: nowrap;
    display: inline-block;
    padding: 5px;
}

.interaction-div img {
    max-width: 18px;
    margin-left: 1px;
}

.inner label {
    z-index: 1;
}

.tag-clickable {
    cursor: pointer;
}

.ant-layout-header {
    z-index: 2;
}

.profile-row-flag {
    font-size: 1.5em;
}

.member-row-col>span {
    margin-right: 1em;
}

.member-row-col img {
    margin-right: 1em;
}

.timestamp {
    white-space: nowrap;
}

.expanded-tag-list span {
    margin: 0px 6px 6px 0px;
}

.search-header {
    background: #fff;
    position: fixed;
    width: 100%;
    padding: 0 8px;
    line-height: 48px;
    height: 48px;
}

.search-input {
    vertical-align: middle;
}

.help-button {
    margin-right: 2em;
}

.help-button span {
    vertical-align: unset;
}

.ant-table-row-expand-icon-cell button {
    width: 2em;
}

.search-help {
    margin-left: 0.25em;
    margin-right: 0.25em;

}

/* Search Toggles */
.search-toggle-switch {
    height: 22px;
    color: #fff;
    background-image: unset;
    margin-top: 4px;
    line-height: 22px;
}

.search-toggle-switch .search-toggle-icon {
    height:18px;
    opacity: 0.5;
}

.ant-switch-checked .search-toggle-icon {
    opacity: 1.0;
}

.search-toggle-switch .ant-switch-inner {
    line-height: 18px;
    top: 2px;
}

.search-toggle-switch .ant-switch-inner .ant-image {
    height: 18px;
    line-height: 18px;
}

.search-toggle-switch .ant-switch-inner .ant-image img {
    margin-top: -2px;
}

.search-toggle-switch{
    margin-left: 0.25em;
    margin-right: 0.25em;
}

.search-toggle-switch.ant-switch .ant-switch-handle {
    border: 2px solid #f3b3b3;
    border-radius: 100px;
}

.search-toggle-switch.ant-switch-checked .ant-switch-handle {
    border: 2px solid #77e78e;
}


.search-toggle-switch.ant-switch{
    background: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2)  ;
}

.search-toggle-switch.ant-switch:hover {
    background: #eaf3fd;
}

.search-toggle-switch.ant-switch:hover .search-toggle-icon {
    opacity: 1;
    transition: 0.25s;
}

.search-drawer {
    display: inline-block;
    border-radius: 2px;
    border: 1px solid transparent;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    height: 32px;
    vertical-align: middle;
}

.search-drawer button {
    vertical-align: top;
}

.search-advanced-controls {
    display: inline-block;
}

.search-help-button, .adv-search-button {
    vertical-align: middle;
}

.search-help-button, .search-drawer {
    margin: 0 0.25em;
}

.adv-search-button {
    border: none;
    height: 30px;
    width: 30px;
}

.adv-search-open svg {
    transform: rotate(90deg);
    transition-duration: 0.25s;
}

.adv-search-closed svg {
    transform: rotate(0deg);
    transition-duration: 0.25s;
}

.search-select-fmx {
    top: 3px;
    vertical-align: top;
    margin-right: 0.25em;
    margin-left: 0.25em;
}

.nav-right-buttons {
   display: inline-block;
    float: right;
}

.nav-right-buttons button {
    margin: 0 0.25em;
}

.nav-right-buttons button:last-of-type {
    margin-right: 0;
}

.profile-connect-tabs, .profile-connect-tabs .ant-tabs-content-top {
    height: 100%;
}

.member-liked-count {
    margin-right: 0.5em!important;
}

.premium-tab-store-icon {
    margin-right: 4px;
    margin-left: 4px;
    height: 1.2em;
    width: auto;
    vertical-align: middle;
}

.member-premium-card .ant-card-meta {
    margin-bottom: 0.5em;
}

.member-premium-card .ant-typography {
    margin-left: 2.25em;
}

.member-premium-section {
    margin-bottom: 1em;
}

.ant-divider-inner-text img {
    vertical-align: middle;
    height: 1.4em;
}

.member-premium-card .ant-col {
    margin: 0.5em 0;
}

.table-link-button{
    padding: 0;
}
.table-button {
    margin: 0 0.25em;
    min-height: unset;
    min-width: unset;
    width: 1.6em;
    height: 1.6em;
}

.table-button svg {
    max-height: 0.75em;
}

.report-table .ant-table-row-level-0 {
    background-color: rgba(0, 0, 0, 0.02)!important;
}

.report-table td, .report-table tr {
    padding: 0!important;
    background-color: transparent!important;
}

.report-macro-button-group {
    padding: 4px 4px 12px 4px;
}

.report-macro-button-group .ant-btn-loading{
    vertical-align: top;
}

.divider-header span {
    font-size: 1.5em;
}

.moderation-table .timestamp {
    line-height: 1.2em;
}

.moderation-table .ant-tag {
    font-size: 0.8em;
    padding: 0 4px;
}


.moderation-table-expanded-row > .ant-table-cell {
    padding-left: 24px!important;
}

.badge-no-text .ant-badge-status-text {
    display: none;
}

.main-menu .anticon {
    min-width: 24px;
    font-size: 24px;
}

.expanded-profile-hashtag-container {
    overflow-x: hidden;
    width:95%;
}
