.settings{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    float: left;
    height: auto;
    width: 100%;
    background-color: #fff;
}

.menu{
    width: 200px;
}

.menu-ig{
    width: 200px;
}

.macro-list{
    margin: 0 10px 50px 20px;
    max-width: 700px;

}

.macro-form{
    text-align: left;
}

.help-popover {
    max-width: 600px;
}

.ant-form-item {
    margin-bottom: 12px;
}
